import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import color from '../../styles/color'

const NewsDetailPage = ({ data }) => {
  const post = data.shopifyArticle

  const { contentHtml, title, publishedAt } = post

  return (
    <Container>
      <Head>
        <Title>{title}</Title>
        <Day>{publishedAt}</Day>
      </Head>

      <Content dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </Container>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyArticle(handle: { eq: $handle }) {
      id
      handle
      title
      contentHtml
      publishedAt(formatString: "YYYY.M.D")
    }
  }
`

export default NewsDetailPage

export const Container = styled.div`
  padding: 15px;
  max-width: 1000px;
  margin: 0 auto;
`

export const Head = styled.div`
  margin-bottom: 20px;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`

export const Day = styled.div`
  font-size: 14px;
`

export const Content = styled.div`
  font-size: 15px;

  img {
    width: 100%;
  }

  a {
    color: ${color.THEMA};
  }
`
